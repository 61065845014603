import { useState, useEffect, lazy, Suspense, useTransition,useLayoutEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { chk_img } from '../components/lang'
import Preloader from '../components/Template/Preloader'
import api from '../components/api'
import styles from '../assets/css/home.module.css';

import Slide from '../components/Homepage/Slide'
import Resource from '../components/Homepage/Resource'
import EBook from '../components/Homepage/Ebook'
// import News from '../components/Homepage/News'
import HomeCalendar from '../components/Homepage/HomeCalendar'
import Activity from '../components/Homepage/Activity'

// const Slide = lazy(() => import ('../components/Homepage/Slide'))
// const Resource = lazy(() => import ('../components/Homepage/Resource'))
// const EBook = lazy(() => import ('../components/Homepage/Ebook'))
// const News = lazy(() => import ('../components/Homepage/News'))
// const HomeCalendar = lazy(() => import ('../components/Homepage/HomeCalendar'))
// const Activity = lazy(() => import ('../components/Homepage/Activity'))

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function Home() {
    const [init, setInit] = useState([]);
	const navigate = useNavigate();
    const [book_style, setBookStyle] = useState({});
    const [news_style, setNewsStyle] = useState({});
    const [activity_style, setActivityStyle] = useState({});
    const [calendar_style, setCalendarStyle] = useState({});
    const [width, height] = useWindowSize();
    // const [isAdmin, setIsAdmin] = useState(false);
    // const [isPending, startTransition] = useTransition();

    reveal();
    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                const response = await api.get('/setting');
                setInit(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
            try {
                const response = await api.get('/article/1');
                const article = response.data.results;
                const detail = JSON.parse(article.detail);
                let book = {"background":detail.book_color}
                if(detail.book_bg){
                    book["backgroundImage"] = `url(${chk_img(detail.book_bg)})`;
                }
                setBookStyle(book);
                let news = {"background":detail.news_color}
                if(detail.news_bg){
                    news["backgroundImage"] = `url(${chk_img(detail.news_bg)})`;
                }
                setNewsStyle(news);
                let activity = {"background":detail.activity_color}
                if(detail.activity_bg){
                    activity["backgroundImage"] = `url(${chk_img(detail.activity_bg)})`;
                }
                setActivityStyle(activity);
                let calendar = {"background":detail.calendar_color}
                if(detail.calendar_bg){
                    calendar["backgroundImage"] = `url(${chk_img(detail.calendar_bg)})`;
                }
                setCalendarStyle(calendar);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
        window.addEventListener("scroll", reveal);
        reveal();
    },[]);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }

    return (
        <>
            <div className="homepage">
                {/* <Suspense fallback={<Preloader text="Slide" />}> */}
                    <div className="main-slide container">
                        <Slide styles={styles} />
                    </div>
                {/* </Suspense>
                <Suspense fallback={<Preloader text="Resource" />}> */}
                    <section className="resource">
                        <Resource styles={styles} />
                    </section>
                {/* </Suspense>
                <Suspense fallback={<Preloader text="Book" />}> */}
                    {/* <section className="book reveal" style={book_style}> */}
                    <section className="book reveal">
                        <EBook styles={styles} />
                    </section>
                {/* </Suspense> */}
                {/* <Suspense fallback={<h1>News Loading</h1>}>
                    <section className="news reveal" style={news_style}>
                        <News styles={styles} />
                    </section>
                </Suspense> */}
                {/* <Suspense fallback={<Preloader text="Activity" />}> */}
                    {/* <section className="activity reveal" style={activity_style}> */}
                    <section className="activity reveal" style={activity_style}>
                        <Activity styles={styles} />
                    </section>
                {/* </Suspense>
                <Suspense fallback={<Preloader text="Calendar" />}> */}
                    <section className="calendar"style={calendar_style}>
                        <HomeCalendar styles={styles} init={init} />
                    </section>
                {/* </Suspense> */}
            </div>
        </>
    )
}